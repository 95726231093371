<template>
  <v-row justify="center">
    <v-dialog v-model="addressDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{
            __("Add New Address")
          }}</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="frappe._('Address Name')"
                    density="compact"
                    variant="outlined"
                    color="primary"
                    background-color="white"
                    hide-details
                    v-model="address.name"
                    :rules="[(v) => !!v || 'Address Name is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    density="compact"
                    variant="outlined"
                    color="primary"
                    :label="frappe._('Address Line 1')"
                    background-color="white"
                    hide-details
                    v-model="address.address_line1"
                    :rules="[(v) => !!v || 'Address Line 1 is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    density="compact"
                    variant="outlined"
                    color="primary"
                    :label="frappe._('Address Line 2')"
                    background-color="white"
                    hide-details
                    v-model="address.address_line2"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="frappe._('City')"
                    density="compact"
                    variant="outlined"
                    color="primary"
                    background-color="white"
                    hide-details
                    v-model="address.city"
                    :rules="[(v) => !!v || 'City is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="frappe._('State')"
                    density="compact"
                    variant="outlined"
                    background-color="white"
                    hide-details
                    v-model="address.state"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="close_dialog">{{
            __("Close")
          }}</v-btn>
          <v-btn color="success" dark @click="submit_dialog">{{
            __("Submit")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    addressDialog: false,
    address: {},
    customer: "",
  }),

  watch: {
    addressDialog(val) {
      if (!val) {
        this.customer = "";
        this.address = {};
      }
    },
  },

  methods: {
    close_dialog() {
      this.addressDialog = false;
    },

    async submit_dialog() {
      const vm = this;
      // validate the form
      const formValid = await this.$refs.form.validate();
      if (!formValid.valid) {
        vm.emitter.emit("show_mesage", {
          text: "Please fill all the required fields.",
          color: "error",
        });
        return;
      }
      this.address.customer = this.customer;
      this.address.doctype = "Customer";
      frappe.call({
        method: "posawesome.posawesome.api.posapp.make_address",
        args: {
          args: this.address,
        },
        callback: (r) => {
          if (!r.exc) {
            vm.emitter.emit("add_the_new_address", r.message);
            vm.emitter.emit("show_mesage", {
              text: "Customer Address created successfully.",
              color: "success",
            });
            vm.addressDialog = false;
            vm.customer = "";
            vm.address = {};
          }
        },
      });
    },
  },
  created: function () {
    this.emitter.on("open_new_address", (data) => {
      this.addressDialog = true;
      this.customer = data;
    });
  },
};
</script>
