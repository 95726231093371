import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-h6 primary--text" }
const _hoisted_2 = {
  class: "my-0 py-0 overflow-y-auto",
  style: {"max-height":"75vh"}
}
const _hoisted_3 = ["colspan"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      class: "selection mx-auto bg-grey-lighten-5",
      style: {"max-height":"80vh","height":"80vh"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Offers")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_data_table, {
            onMouseover: _cache[0] || (_cache[0] = $event => (_ctx.style = 'cursor: pointer')),
            headers: _ctx.items_headers,
            items: _ctx.pos_offers,
            "single-expand": _ctx.singleExpand,
            expanded: _ctx.expanded,
            "show-expand": "",
            "item-value": "row_id",
            class: "elevation-1",
            "items-per-page": "-1"
          }, {
            "item.offer_applied": _withCtx(({ item }) => [
              _createVNode(_component_v_checkbox, {
                onClick: $options.forceUpdateItem,
                modelValue: item.offer_applied,
                "onUpdate:modelValue": $event => ((item.offer_applied) = $event),
                density: "compact",
                "hide-details": "",
                disabled: 
                (item.offer == 'Give Product' &&
                  !item.give_item &&
                  (!item.replace_cheapest_item || !item.replace_item)) ||
                (item.offer == 'Grand Total' &&
                  _ctx.discount_percentage_offer_name &&
                  _ctx.discount_percentage_offer_name != item.name)
              
              }, null, 8 /* PROPS */, ["onClick", "modelValue", "onUpdate:modelValue", "disabled"])
            ]),
            "expanded-row": _withCtx(({ columns, item }) => [
              _createElementVNode("td", {
                colspan: columns.length
              }, [
                _createVNode(_component_v_row, { class: "p-3" }, {
                  default: _withCtx(() => [
                    (item.description)
                      ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: "primary--text",
                              innerHTML: $options.handleNewLine(item.description)
                            }, null, 8 /* PROPS */, _hoisted_4)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (item.offer == 'Give Product')
                      ? (_openBlock(), _createBlock(_component_v_col, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, {
                              density: "compact",
                              variant: "outlined",
                              modelValue: item.give_item,
                              "onUpdate:modelValue": $event => ((item.give_item) = $event),
                              color: "primary",
                              items: $options.get_give_items(item),
                              "item-title": "name",
                              "item-value": "code",
                              label: _ctx.frappe._('Give Item'),
                              "hide-details": "",
                              disabled: 
                      item.apply_type != 'Item Group' ||
                      item.replace_item ||
                      item.replace_cheapest_item
                    
                            }, {
                              item: _withCtx(({ props, item }) => [
                                _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                                  default: _withCtx(() => [
                                    (item.raw.name != item.raw.code)
                                      ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                                          key: 0,
                                          innerHTML: `Code: ${item.raw.code}`
                                        }, null, 8 /* PROPS */, ["innerHTML"]))
                                      : _createCommentVNode("v-if", true)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "items", "label", "disabled"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ], 8 /* PROPS */, _hoisted_3)
            ]),
            bottom: _withCtx(() => []),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["headers", "items", "single-expand", "expanded"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_card, {
      flat: "",
      style: {"max-height":"11vh","height":"11vh"},
      class: "cards mb-0 mt-3 py-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "start",
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  block: "",
                  class: "pa-1",
                  large: "",
                  color: "warning",
                  dark: "",
                  onClick: $options.back_to_invoice
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Back")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}