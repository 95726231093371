import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline primary--text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: $data.isOpen,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.isOpen) = $event)),
        persistent: "",
        "max-width": "600px"
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" <template v-slot:activator=\"{ on, attrs }\">\n        <v-btn color=\"primary\" dark v-bind=\"attrs\" v-on=\"on\">Open Dialog</v-btn>\n      </template>"),
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Create POS Opening Shift")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_autocomplete, {
                                items: $data.companies,
                                label: _ctx.frappe._('Company'),
                                modelValue: $data.company,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.company) = $event)),
                                required: ""
                              }, null, 8 /* PROPS */, ["items", "label", "modelValue"])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_autocomplete, {
                                items: $data.pos_profiles,
                                label: _ctx.frappe._('POS Profile'),
                                modelValue: $data.pos_profile,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.pos_profile) = $event)),
                                required: ""
                              }, null, 8 /* PROPS */, ["items", "label", "modelValue"])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_data_table, {
                                headers: $data.payments_methods_headers,
                                items: $data.payments_methods,
                                "item-value": "mode_of_payment",
                                class: "elevation-1",
                                "items-per-page": "-1",
                                "hide-default-footer": ""
                              }, {
                                "item.amount": _withCtx((props) => [
                                  _createVNode(_component_v_text_field, {
                                    modelValue: props.item.amount,
                                    "onUpdate:modelValue": $event => ((props.item.amount) = $event),
                                    rules: [$data.max25chars],
                                    density: "compact",
                                    variant: "plain",
                                    prefix: _ctx.currencySymbol(props.item.currency),
                                    type: "number",
                                    "hide-details": ""
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "rules", "prefix"])
                                ]),
                                bottom: _withCtx(() => []),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["headers", "items"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    dark: "",
                    onClick: $options.go_desk
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cancel")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    disabled: $data.is_loading,
                    dark: "",
                    onClick: $options.submit_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Submit")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled", "onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}