<template>
  <div fluid class="mt-0">
    <ClosingDialog></ClosingDialog>
    <Drafts></Drafts>
    <SalesOrders></SalesOrders>
    <Returns></Returns>
    <NewAddress></NewAddress>
    <MpesaPayments></MpesaPayments>
    <Variants></Variants>
    <OpeningDialog v-if="dialog" :dialog="dialog"></OpeningDialog>
    <v-row v-show="!dialog">
      <v-col
        v-show="!payment && !offers && !coupons"
        xl="5"
        lg="5"
        md="5"
        sm="5"
        cols="12"
        class="pos pr-0"
      >
        <ItemsSelector></ItemsSelector>
      </v-col>
      <v-col
        v-show="offers"
        xl="5"
        lg="5"
        md="5"
        sm="5"
        cols="12"
        class="pos pr-0"
      >
        <PosOffers></PosOffers>
      </v-col>
      <v-col
        v-show="coupons"
        xl="5"
        lg="5"
        md="5"
        sm="5"
        cols="12"
        class="pos pr-0"
      >
        <PosCoupons></PosCoupons>
      </v-col>
      <v-col
        v-show="payment"
        xl="5"
        lg="5"
        md="5"
        sm="5"
        cols="12"
        class="pos pr-0"
      >
        <Payments></Payments>
      </v-col>

      <v-col xl="7" lg="7" md="7" sm="7" cols="12" class="pos">
        <Invoice></Invoice>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemsSelector from "./ItemsSelector.vue";
import Invoice from "./Invoice.vue";
import OpeningDialog from "./OpeningDialog.vue";
import Payments from "./Payments.vue";
import PosOffers from "./PosOffers.vue";
import PosCoupons from "./PosCoupons.vue";
import Drafts from "./Drafts.vue";
import SalesOrders from "./SalesOrders.vue";
import ClosingDialog from "./ClosingDialog.vue";
import NewAddress from "./NewAddress.vue";
import Variants from "./Variants.vue";
import Returns from "./Returns.vue";
import MpesaPayments from "./Mpesa-Payments.vue";

export default {
  data: function () {
    return {
      dialog: false,
      pos_profile: "",
      pos_opening_shift: "",
      payment: false,
      offers: false,
      coupons: false,
    };
  },

  components: {
    ItemsSelector,
    Invoice,
    OpeningDialog,
    Payments,
    Drafts,
    ClosingDialog,

    Returns,
    PosOffers,
    PosCoupons,
    NewAddress,
    Variants,
    MpesaPayments,
    SalesOrders,
  },

  methods: {
    check_opening_entry() {
      const vm = this;
      return frappe
        .call("posawesome.posawesome.api.posapp.check_opening_shift", {
          user: frappe.session.user,
        })
        .then((r) => {
          if (r.message) {
            vm.pos_profile = r.message.pos_profile;
            vm.pos_opening_shift = r.message.pos_opening_shift;
            vm.get_offers(this.pos_profile.name);
            vm.emitter.emit("register_pos_profile", r.message);
            vm.emitter.emit("set_company", r.message.company);
            console.info("LoadPosProfile");
          } else {
            vm.create_opening_voucher();
          }
        });
    },
    create_opening_voucher() {
      this.dialog = true;
    },
    get_closing_data() {
      return frappe
        .call(
          "posawesome.posawesome.doctype.pos_closing_shift.pos_closing_shift.make_closing_shift_from_opening",
          {
            opening_shift: this.pos_opening_shift,
          }
        )
        .then((r) => {
          if (r.message) {
            this.emitter.emit("open_ClosingDialog", r.message);
          } else {
            // console.log(r);
          }
        });
    },
    submit_closing_pos(data) {
      const vm = this;
      frappe
        .call(
          "posawesome.posawesome.doctype.pos_closing_shift.pos_closing_shift.submit_closing_shift",
          {
            closing_shift: data,
          }
        )
        .then((r) => {
          if (r.message) {
            vm.emitter.emit("show_mesage", {
              text: `POS Shift Closed`,
              color: "success",
            });
            vm.check_opening_entry();
          } else {
            console.log(r);
          }
        });
    },
    get_offers(pos_profile) {
      const vm = this;
      return frappe
        .call("posawesome.posawesome.api.posapp.get_offers", {
          profile: pos_profile,
        })
        .then((r) => {
          if (r.message) {
            console.info("LoadOffers");
            vm.emitter.emit("set_offers", r.message);
          }
        });
    },
    get_pos_setting() {
      const vm = this;
      frappe.db.get_doc("POS Settings", undefined).then((doc) => {
        vm.emitter.emit("set_pos_settings", doc);
      });
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.check_opening_entry();
      this.get_pos_setting();
      this.emitter.on("close_opening_dialog", () => {
        this.dialog = false;
      });
      this.emitter.on("register_pos_data", (data) => {
        this.pos_profile = data.pos_profile;
        this.get_offers(this.pos_profile.name);
        this.pos_opening_shift = data.pos_opening_shift;
        this.emitter.emit("register_pos_profile", data);
        console.info("LoadPosProfile");
      });
      this.emitter.on("show_payment", (data) => {
        this.payment = true ? data === "true" : false;
        this.offers = false ? data === "true" : false;
        this.coupons = false ? data === "true" : false;
      });
      this.emitter.on("show_offers", (data) => {
        this.offers = true ? data === "true" : false;
        this.payment = false ? data === "true" : false;
        this.coupons = false ? data === "true" : false;
      });
      this.emitter.on("show_coupons", (data) => {
        this.coupons = true ? data === "true" : false;
        this.offers = false ? data === "true" : false;
        this.payment = false ? data === "true" : false;
      });
      this.emitter.on("open_closing_dialog", () => {
        this.get_closing_data();
      });
      this.emitter.on("submit_closing_pos", (data) => {
        this.submit_closing_pos(data);
      });
    });
  },
  beforeDestroy() {
    this.emitter.off("close_opening_dialog");
    this.emitter.off("register_pos_data");
    this.emitter.off("LoadPosProfile");
    this.emitter.off("show_offers");
    this.emitter.off("show_coupons");
    this.emitter.off("open_closing_dialog");
    this.emitter.off("submit_closing_pos");
  },
};
</script>

<style scoped></style>
