import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"none"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_UpdateCustomer = _resolveComponent("UpdateCustomer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_autocomplete, {
      id: "customer",
      density: "compact",
      clearable: "",
      "auto-select-first": "",
      variant: "outlined",
      color: "primary",
      label: _ctx.frappe._('Customer'),
      modelValue: _ctx.customer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.customer) = $event)),
      items: _ctx.customers,
      "item-title": "customer_name",
      "item-value": "name",
      "background-color": "white",
      "no-data-text": _ctx.__('Customer not found'),
      "hide-details": "",
      customFilter: $options.customFilter,
      disabled: _ctx.readonly,
      "append-inner-icon": "mdi-plus",
      "onClick:appendInner": _withModifiers($options.new_customer, ["prevent"]),
      "prepend-inner-icon": "mdi-account-edit",
      "onClick:prependInner": $options.edit_customer
    }, {
      item: _withCtx(({ props, item }) => [
        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item_title, {
              class: "primary--text subtitle-1",
              innerHTML: item.raw.customer_name
            }, null, 8 /* PROPS */, ["innerHTML"]),
            (item.raw.customer_name != item.raw.name)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 0,
                  innerHTML: `ID: ${item.raw.name}`
                }, null, 8 /* PROPS */, ["innerHTML"]))
              : _createCommentVNode("v-if", true),
            (item.raw.tax_id)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 1,
                  innerHTML: `TAX ID: ${item.raw.tax_id}`
                }, null, 8 /* PROPS */, ["innerHTML"]))
              : _createCommentVNode("v-if", true),
            (item.raw.email_id)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 2,
                  innerHTML: `Email: ${item.raw.email_id}`
                }, null, 8 /* PROPS */, ["innerHTML"]))
              : _createCommentVNode("v-if", true),
            (item.raw.mobile_no)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 3,
                  innerHTML: `Mobile No: ${item.raw.mobile_no}`
                }, null, 8 /* PROPS */, ["innerHTML"]))
              : _createCommentVNode("v-if", true),
            (item.raw.primary_address)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 4,
                  innerHTML: `Primary Address: ${item.raw.primary_address}`
                }, null, 8 /* PROPS */, ["innerHTML"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "customFilter", "disabled", "onClick:appendInner", "onClick:prependInner"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UpdateCustomer)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}