<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        :label="label"
        :model-value="formattedDate"
        readonly
        v-bind="props"
        density="compact"
        variant="outlined"
        hide-details
        :color="color"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      hide-actions
      title=""
      :color="color"
      density="compact"
      :min="minDate"
      :max="maxDate"
      @update:modelValue="isMenuOpen = false"
    >
      <template v-slot:header></template>
    </v-date-picker>
  </v-menu>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const { label, color, modelValue, min, max } = defineProps([
  "label",
  "color",
  "modelValue",
  "min",
  "max",
]);
const emit = defineEmits("update:modelValue");

const minDate = computed(() => {
  // check if props.min is "Default" or is not set

  if (min === "Default" || !min) {
    return frappe.datetime.now_date();
  }
  // check if props.min is a valid date
  if (frappe.datetime.validate(min)) {
    return min;
  }
  // check if props.min is "False"
  if (min === "False") {
    return null;
  }
  // return today's date if props.min is not valid
  return frappe.datetime.now_date();
});

const maxDate = computed(() => {
  // check if props.max is "Default" or "Today"
  if (max === "Default" || max === "Today") {
    return frappe.datetime.now_date();
  }
  // check if props.max is a valid date
  if (frappe.datetime.validate(max)) {
    return max;
  }
  // check if props.max is "False" or is not set
  if (max === "False" || !max) {
    return null;
  }
  // return today's date if props.max is not valid
  return frappe.datetime.now_date();
});

const isMenuOpen = ref(false);
const selectedDate = ref(modelValue ? new Date(modelValue) : null);

const formattedDate = computed(() => {
  // check if selectedDate is a valid date or string date
  // if it is a date object, convert it to string date
  // if it is a string date, convert it to date object then to string date

  if (!selectedDate.value) {
    return "";
  }

  if (selectedDate.value instanceof Date) {
    return selectedDate.value.toLocaleDateString("en-GB");
  } else {
    // if it is a string date, convert it to date object then to string date
    newDate = new Date(selectedDate.value);
    return newDate.toLocaleDateString("en-GB");
  }
});

watch(modelValue, (newDate) => {
  selectedDate.value = newDate;
});

watch(selectedDate, (newDate) => {
  // convert to string date format "yyyy-mm-dd"
  const newDateString = frappe.datetime.obj_to_str(newDate);
  emit("update:modelValue", newDateString);
});
</script>
<style>
.v-overlay__content:has(> .v-date-picker) {
  min-width: auto !important;
}
.v-picker-title {
  padding: 0 !important;
}
</style>
`
