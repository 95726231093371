<template>
  <v-row justify="center">
    <v-dialog v-model="variantsDialog" max-width="600px">
      <v-card min-height="500px">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="headline text-primary">
            {{ parentItem.item_name }}
          </div>
          <v-btn
            color="error"
            icon="mdi-close"
            variant="text"
            density="compact"
            dark
            @click="close_dialog"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container v-if="parentItem" class="pt-0">
            <div v-for="attr in parentItem.attributes" :key="attr.attribute">
              <div>
                <div class="text-subtitle-2 text-warning">
                  {{ attr.attribute }}
                </div>
              </div>
              <v-chip-group
                v-model="filters[attr.attribute]"
                selected-class="text-success"
                column
              >
                <div v-for="value in attr.values" :key="value.abbr">
                  <v-chip
                    :value="value.attribute_value"
                    color="primary"
                    variant="tonal"
                    style="color: #757575"
                    @click="updateFilteredItems"
                  >
                    {{ value.attribute_value }}
                  </v-chip>
                </div>
              </v-chip-group>
              <v-divider class="p-0 m-0 text-grey-darken-1"></v-divider>
            </div>
            <div>
              <div v-if="!filteredItems.length" class="text-center mt-16">
                <div class="text-subtitle-2 text-error">
                  <v-alert type="primary" variant="outlined" icon="$info">
                    {{ __("No items found") }}
                  </v-alert>
                </div>
              </div>
              <v-row
                v-else
                dense
                class="overflow-y-auto mt-3"
                style="max-height: 500px"
              >
                <v-col
                  v-for="(item, idx) in filteredItems"
                  :key="idx"
                  xl="2"
                  lg="3"
                  md="4"
                  sm="4"
                  cols="6"
                  min-height="50"
                >
                  <v-card hover="hover" @click="add_item(item)">
                    <v-img
                      :src="
                        item.image ||
                        '/assets/posawesome/js/posapp/components/pos/placeholder-image.png'
                      "
                      cover
                      class="text-white align-end"
                      gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.7)"
                      height="100px"
                    >
                      <v-card-text
                        v-text="item.item_name"
                        class="text-subtitle-2 px-1 pb-2"
                      ></v-card-text>
                    </v-img>
                    <v-card-text class="text-primary pa-1">
                      <div class="text-caption text-primary">
                        {{ currencySymbol(item.currency) || "" }}
                        {{ formatCurrency(item.rate) || 0 }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import format from "../../format";
export default {
  mixins: [format],
  data: () => ({
    variantsDialog: false,
    parentItem: null,
    items: null,
    filters: {},
    filteredItems: [],
  }),

  computed: {
    variantsItems() {
      if (!this.parentItem) {
        return [];
      } else {
        return this.items.filter(
          (item) => item.variant_of == this.parentItem.item_code
        );
      }
    },
  },

  methods: {
    close_dialog() {
      this.variantsDialog = false;
    },
    // formtCurrency(value) {
    //   value = parseFloat(value);
    //   return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    // },
    updateFilteredItems() {
      this.$nextTick(function () {
        const values = [];
        Object.entries(this.filters).forEach(([key, value]) => {
          if (value) {
            values.push(value);
          }
        });

        if (!values.length) {
          this.filteredItems = this.variantsItems;
        } else {
          const itemsList = [];
          this.filteredItems = [];
          this.variantsItems.forEach((item) => {
            let apply = true;
            item.item_attributes.forEach((attr) => {
              if (
                this.filters[attr.attribute] &&
                this.filters[attr.attribute] != attr.attribute_value
              ) {
                apply = false;
              }
            });
            if (apply && !itemsList.includes(item.item_code)) {
              this.filteredItems.push(item);
              itemsList.push(item.item_code);
            }
          });
        }
      });
    },
    add_item(item) {
      this.emitter.emit("add_item", item);
      this.close_dialog();
    },
  },

  created: function () {
    this.emitter.on("open_variants_model", (data) => {
      const { item, items } = data;
      this.variantsDialog = true;
      this.parentItem = item || null;
      this.items = items;
      this.filters = {};
      this.$nextTick(function () {
        this.filteredItems = this.variantsItems;
      });
    });
  },
};
</script>
