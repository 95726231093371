import { createApp } from "vue";
import Home from "./Home.vue";
import mitt from "mitt";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
// import DayJsAdapter from "@date-io/dayjs";

class POSClass {
  constructor({ parent }) {
    this.$parent = $(document);
    this.page = parent.page;
    this.make_body();
  }
  make_body() {
    const $mainSection = this.$parent.find(".main-section");
    // reset the main section
    $mainSection.empty();
    // add div with id app to the main section
    $("<div id='app'></div>").appendTo($mainSection);
    let app = createApp(Home);
    const emitter = mitt();
    app.config.globalProperties.emitter = emitter;
    const vuetify = createVuetify({
      components,
      directives,
      rtl: frappe.utils.is_rtl(),
      // date: {
      //   adapter: DayJsAdapter,
      // },
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            colors: {
              background: "#FFFFFF",
              primary: "#0097A7",
              secondary: "#00BCD4",
              accent: "#9575CD",
              success: "#66BB6A",
              info: "#2196F3",
              warning: "#FF9800",
              error: "#E86674",
              orange: "#E65100",
              golden: "#A68C59",
              badge: "#F5528C",
              customPrimary: "#085294",
            },
          },
          variables: {},
        },
      },
    });
    app.use(vuetify);
    SetVueGlobals(app);

    // mount the app
    console.info("Mounting App");
    this.$pos_app = app.mount("#app");
    this.app = app;
  }
}

frappe.provide("frappe.PosAwesome");
frappe.PosAwesome.POSClass = POSClass;
export default POSClass;
