import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline text-primary" }
const _hoisted_2 = { class: "text-subtitle-2 text-warning" }
const _hoisted_3 = {
  key: 0,
  class: "text-center mt-16"
}
const _hoisted_4 = { class: "text-subtitle-2 text-error" }
const _hoisted_5 = { class: "text-caption text-primary" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_chip = _resolveComponent("v-chip")
  const _component_v_chip_group = _resolveComponent("v-chip-group")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_img = _resolveComponent("v-img")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.variantsDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.variantsDialog) = $event)),
        "max-width": "600px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { "min-height": "500px" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "d-flex justify-space-between align-center" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.parentItem.item_name), 1 /* TEXT */),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    icon: "mdi-close",
                    variant: "text",
                    density: "compact",
                    dark: "",
                    onClick: $options.close_dialog
                  }, null, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  (_ctx.parentItem)
                    ? (_openBlock(), _createBlock(_component_v_container, {
                        key: 0,
                        class: "pt-0"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentItem.attributes, (attr) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: attr.attribute
                            }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_2, _toDisplayString(attr.attribute), 1 /* TEXT */)
                              ]),
                              _createVNode(_component_v_chip_group, {
                                modelValue: _ctx.filters[attr.attribute],
                                "onUpdate:modelValue": $event => ((_ctx.filters[attr.attribute]) = $event),
                                "selected-class": "text-success",
                                column: ""
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attr.values, (value) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: value.abbr
                                    }, [
                                      _createVNode(_component_v_chip, {
                                        value: value.attribute_value,
                                        color: "primary",
                                        variant: "tonal",
                                        style: {"color":"#757575"},
                                        onClick: $options.updateFilteredItems
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(value.attribute_value), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"])
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_v_divider, { class: "p-0 m-0 text-grey-darken-1" })
                            ]))
                          }), 128 /* KEYED_FRAGMENT */)),
                          _createElementVNode("div", null, [
                            (!_ctx.filteredItems.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createElementVNode("div", _hoisted_4, [
                                    _createVNode(_component_v_alert, {
                                      type: "primary",
                                      variant: "outlined",
                                      icon: "$info"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.__("No items found")), 1 /* TEXT */)
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ])
                                ]))
                              : (_openBlock(), _createBlock(_component_v_row, {
                                  key: 1,
                                  dense: "",
                                  class: "overflow-y-auto mt-3",
                                  style: {"max-height":"500px"}
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, idx) => {
                                      return (_openBlock(), _createBlock(_component_v_col, {
                                        key: idx,
                                        xl: "2",
                                        lg: "3",
                                        md: "4",
                                        sm: "4",
                                        cols: "6",
                                        "min-height": "50"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, {
                                            hover: "hover",
                                            onClick: $event => ($options.add_item(item))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_img, {
                                                src: 
                        item.image ||
                        '/assets/posawesome/js/posapp/components/pos/placeholder-image.png'
                      ,
                                                cover: "",
                                                class: "text-white align-end",
                                                gradient: "to bottom, rgba(0,0,0,.2), rgba(0,0,0,.7)",
                                                height: "100px"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_card_text, {
                                                    textContent: _toDisplayString(item.item_name),
                                                    class: "text-subtitle-2 px-1 pb-2"
                                                  }, null, 8 /* PROPS */, ["textContent"])
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src"]),
                                              _createVNode(_component_v_card_text, { class: "text-primary pa-1" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currencySymbol(item.currency) || "") + " " + _toDisplayString(_ctx.formatCurrency(item.rate) || 0), 1 /* TEXT */)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1024 /* DYNAMIC_SLOTS */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}