import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline primary--text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_form = _resolveComponent("v-form")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.addressDialog,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.addressDialog) = $event)),
        "max-width": "600px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Add New Address")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, { ref: "form" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: _ctx.frappe._('Address Name'),
                                    density: "compact",
                                    variant: "outlined",
                                    color: "primary",
                                    "background-color": "white",
                                    "hide-details": "",
                                    modelValue: _ctx.address.name,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.address.name) = $event)),
                                    rules: [(v) => !!v || 'Address Name is required'],
                                    required: ""
                                  }, null, 8 /* PROPS */, ["label", "modelValue", "rules"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    density: "compact",
                                    variant: "outlined",
                                    color: "primary",
                                    label: _ctx.frappe._('Address Line 1'),
                                    "background-color": "white",
                                    "hide-details": "",
                                    modelValue: _ctx.address.address_line1,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.address.address_line1) = $event)),
                                    rules: [(v) => !!v || 'Address Line 1 is required'],
                                    required: ""
                                  }, null, 8 /* PROPS */, ["label", "modelValue", "rules"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    density: "compact",
                                    variant: "outlined",
                                    color: "primary",
                                    label: _ctx.frappe._('Address Line 2'),
                                    "background-color": "white",
                                    "hide-details": "",
                                    modelValue: _ctx.address.address_line2,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.address.address_line2) = $event))
                                  }, null, 8 /* PROPS */, ["label", "modelValue"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: _ctx.frappe._('City'),
                                    density: "compact",
                                    variant: "outlined",
                                    color: "primary",
                                    "background-color": "white",
                                    "hide-details": "",
                                    modelValue: _ctx.address.city,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.address.city) = $event)),
                                    rules: [(v) => !!v || 'City is required'],
                                    required: ""
                                  }, null, 8 /* PROPS */, ["label", "modelValue", "rules"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: _ctx.frappe._('State'),
                                    density: "compact",
                                    variant: "outlined",
                                    "background-color": "white",
                                    "hide-details": "",
                                    modelValue: _ctx.address.state,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.address.state) = $event))
                                  }, null, 8 /* PROPS */, ["label", "modelValue"])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 512 /* NEED_PATCH */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    dark: "",
                    onClick: $options.close_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Close")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    dark: "",
                    onClick: $options.submit_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Submit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}