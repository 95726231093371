<template>
  <v-row justify="center">
    <v-dialog v-model="draftsDialog" max-width="900px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">Open Dialog</v-btn>
      </template>-->
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{
            __("Select Hold Invoice")
          }}</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" class="pa-1">
                <v-data-table
                  :headers="headers"
                  :items="dialog_data"
                  item-value="name"
                  class="elevation-1"
                  select-strategy="single"
                  show-select
                  v-model="selected"
                >
                  <template v-slot:item.posting_time="{ item }">
                    {{ item.posting_time.split(".")[0] }}
                  </template>
                  <template v-slot:item.grand_total="{ item }">
                    {{ currencySymbol(item.currency) }}
                    {{ formatCurrency(item.grand_total) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="close_dialog">Close</v-btn>
          <v-btn color="success" dark @click="submit_dialog">Select</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import format from "../../format";
export default {
  // props: ["draftsDialog"],
  mixins: [format],
  data: () => ({
    draftsDialog: false,
    selected: [],
    dialog_data: {},
    headers: [
      {
        title: __("Customer"),
        key: "customer_name",
        align: "start",
        sortable: true,
      },
      {
        title: __("Date"),
        key: "posting_date",
        align: "start",
        sortable: true,
      },
      {
        title: __("Time"),
        key: "posting_time",
        align: "start",
        sortable: true,
      },
      {
        title: __("Invoice"),
        key: "name",
        align: "start",
        sortable: true,
      },
      {
        title: __("Amount"),
        key: "grand_total",
        align: "end",
        sortable: false,
      },
    ],
  }),
  methods: {
    close_dialog() {
      this.draftsDialog = false;
    },

    submit_dialog() {
      if (this.selected.length > 0) {
        const invoice_name = this.selected[0];
        if (!invoice_name) {
          return;
        }
        const invoice_doc = this.dialog_data.find(
          (d) => d.name === invoice_name
        );
        if (!invoice_doc) {
          return;
        }
        this.emitter.emit("load_invoice", invoice_doc);
        this.draftsDialog = false;
      }
    },
  },
  created: function () {
    this.emitter.on("open_drafts", (data) => {
      this.draftsDialog = true;
      this.dialog_data = data;
    });
  },
};
</script>
