import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_date_picker = _resolveComponent("v-date-picker")
  const _component_v_menu = _resolveComponent("v-menu")

  return (_openBlock(), _createBlock(_component_v_menu, {
    modelValue: $setup.isMenuOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.isMenuOpen) = $event)),
    "close-on-content-click": false,
    transition: "scale-transition"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_text_field, _mergeProps({
        label: $props.label,
        "model-value": $setup.formattedDate,
        readonly: ""
      }, props, {
        density: "compact",
        variant: "outlined",
        "hide-details": "",
        color: $props.color,
        clearable: ""
      }), null, 16 /* FULL_PROPS */, ["label", "model-value", "color"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_date_picker, {
        modelValue: $setup.selectedDate,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = $event => (($setup.selectedDate) = $event)),
          _cache[1] || (_cache[1] = $event => ($setup.isMenuOpen = false))
        ],
        "hide-actions": "",
        title: "",
        color: $props.color,
        density: "compact",
        min: $setup.minDate,
        max: $setup.maxDate
      }, {
        header: _withCtx(() => []),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "color", "min", "max"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}