<template>
  <nav>
    <v-app-bar app height="40" class="bg-grey-lighten-5">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="text-grey"
      ></v-app-bar-nav-icon>
      <v-img
        src="/assets/posawesome/js/posapp/components/pos/pos.png"
        alt="POS Awesome"
        max-width="32"
        class="mr-2"
      ></v-img>
      <v-toolbar-title
        @click="go_desk"
        style="cursor: pointer"
        class="text-uppercase text-primary"
      >
        <span class="font-weight-light">pos</span>
        <span>awesome</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn style="cursor: unset" text color="primary">
        <span right>{{ pos_profile.name }}</span>
      </v-btn>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn color="primary" dark text v-bind="props">Menu</v-btn>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-list-item-group v-model="menu_item" color="primary">
                <v-list-item
                  @click="close_shift_dialog"
                  v-if="!pos_profile.posa_hide_closing_shift && item == 0"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-content-save-move-outline"></v-icon>
                  </template>
                  <v-list-item-title>{{ __("Close Shift") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="print_last_invoice"
                  v-if="
                    pos_profile.posa_allow_print_last_invoice &&
                    this.last_invoice
                  "
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-printer"></v-icon>
                  </template>
                  <v-list-item-title>{{
                    __("Print Last Invoice")
                  }}</v-list-item-title>
                </v-list-item>
                <v-divider class="my-0"></v-divider>
                <v-list-item @click="logOut">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-logout"></v-icon>
                  </template>
                  <v-list-item-title>{{ __("Logout") }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="go_about">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-information-outline"></v-icon>
                  </template>
                  <v-list-item-title>{{ __("About") }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      expand-on-hover
      rail
      class="bg-cyan-darken-2"
      width="170px"
      theme="dark"
    >
      <v-list dark>
        <v-list-item :prepend-avatar="company_img" :title="company">
        </v-list-item>

        <v-list-item
          v-for="item in items"
          :key="item.text"
          :prepend-icon="item.icon"
          :value="item.text"
          :title="item.text"
          @click="changePage(item.text)"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor" top right>
      {{ snackText }}
    </v-snackbar>
    <v-dialog v-model="freeze" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ freezeTitle }}
        </v-card-title>
        <v-card-text>{{ freezeMsg }}</v-card-text>
      </v-card>
    </v-dialog>
  </nav>
</template>

<script>
export default {
  // components: {MyPopup},
  data() {
    return {
      drawer: false,
      mini: true,
      item: 0,
      items: [{ text: "POS", icon: "mdi-network-pos" }],
      page: "",
      fav: true,
      menu: false,
      message: false,
      hints: true,
      menu_item: 0,
      snack: false,
      snackColor: "",
      snackText: "",
      company: "POS Awesome",
      company_img: "/assets/erpnext/images/erpnext-logo.svg",
      pos_profile: "",
      freeze: false,
      freezeTitle: "",
      freezeMsg: "",
      last_invoice: "",
    };
  },
  methods: {
    changePage(key) {
      this.$emit("changePage", key);
    },
    go_desk() {
      frappe.set_route("/");
      location.reload();
    },
    go_about() {
      const win = window.open(
        "https://github.com/yrestom/POS-Awesome",
        "_blank"
      );
      win.focus();
    },
    close_shift_dialog() {
      this.emitter.emit("open_closing_dialog");
    },
    show_mesage(data) {
      this.snack = true;
      this.snackColor = data.color;
      this.snackText = data.text;
    },
    logOut() {
      var me = this;
      me.logged_out = true;
      return frappe.call({
        method: "logout",
        callback: function (r) {
          if (r.exc) {
            return;
          }
          frappe.set_route("/app/posapp");
          location.reload();
        },
      });
    },
    print_last_invoice() {
      if (!this.last_invoice) return;
      const print_format =
        this.pos_profile.print_format_for_online ||
        this.pos_profile.print_format;
      const letter_head = this.pos_profile.letter_head || 0;
      const url =
        frappe.urllib.get_base_url() +
        "/printview?doctype=Sales%20Invoice&name=" +
        this.last_invoice +
        "&trigger_print=1" +
        "&format=" +
        print_format +
        "&no_letterhead=" +
        letter_head;
      const printWindow = window.open(url, "Print");
      printWindow.addEventListener(
        "load",
        function () {
          printWindow.print();
        },
        true
      );
    },
  },
  created: function () {
    this.$nextTick(function () {
      this.emitter.on("show_mesage", (data) => {
        this.show_mesage(data);
      });
      this.emitter.on("set_company", (data) => {
        this.company = data.name;
        this.company_img = data.company_logo
          ? data.company_logo
          : this.company_img;
      });
      this.emitter.on("register_pos_profile", (data) => {
        this.pos_profile = data.pos_profile;
        const payments = { text: "Payments", icon: "mdi-cash-register" };
        if (
          this.pos_profile.posa_use_pos_awesome_payments &&
          this.items.length !== 2
        ) {
          this.items.push(payments);
        }
      });
      this.emitter.on("set_last_invoice", (data) => {
        this.last_invoice = data;
      });
      this.emitter.on("freeze", (data) => {
        this.freeze = true;
        this.freezeTitle = data.title;
        this.freezeMsg = data.msg;
      });
      this.emitter.on("unfreeze", () => {
        this.freeze = false;
        this.freezTitle = "";
        this.freezeMsg = "";
      });
    });
  },
};
</script>

<style scoped>
.margen-top {
  margin-top: 0px;
}
</style>
