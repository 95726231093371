<template>
  <div>
    <v-card
      class="selection mx-auto bg-grey-lighten-5"
      style="max-height: 80vh; height: 80vh"
    >
      <v-card-title>
        <span class="text-h6 primary--text">{{ __("Offers") }}</span>
      </v-card-title>
      <div class="my-0 py-0 overflow-y-auto" style="max-height: 75vh">
        <v-data-table
          @mouseover="style = 'cursor: pointer'"
          :headers="items_headers"
          :items="pos_offers"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          item-value="row_id"
          class="elevation-1"
          items-per-page="-1"
        >
          <template v-slot:item.offer_applied="{ item }">
            <v-checkbox
              @click="forceUpdateItem"
              v-model="item.offer_applied"
              density="compact"
              hide-details
              :disabled="
                (item.offer == 'Give Product' &&
                  !item.give_item &&
                  (!item.replace_cheapest_item || !item.replace_item)) ||
                (item.offer == 'Grand Total' &&
                  discount_percentage_offer_name &&
                  discount_percentage_offer_name != item.name)
              "
            ></v-checkbox>
          </template>
          <template v-slot:expanded-row="{ columns, item }">
            <td :colspan="columns.length">
              <v-row class="p-3">
                <v-col v-if="item.description">
                  <div
                    class="primary--text"
                    v-html="handleNewLine(item.description)"
                  ></div>
                </v-col>
                <v-col v-if="item.offer == 'Give Product'">
                  <v-select
                    density="compact"
                    variant="outlined"
                    v-model="item.give_item"
                    color="primary"
                    :items="get_give_items(item)"
                    item-title="name"
                    item-value="code"
                    :label="frappe._('Give Item')"
                    hide-details
                    :disabled="
                      item.apply_type != 'Item Group' ||
                      item.replace_item ||
                      item.replace_cheapest_item
                    "
                  >
                    <template v-slot:item="{ props, item }">
                      <v-list-item v-bind="props">
                        <v-list-item-subtitle
                          v-if="item.raw.name != item.raw.code"
                          v-html="`Code: ${item.raw.code}`"
                        >
                        </v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:bottom></template>
        </v-data-table>
      </div>
    </v-card>

    <v-card
      flat
      style="max-height: 11vh; height: 11vh"
      class="cards mb-0 mt-3 py-0"
    >
      <v-row align="start" no-gutters>
        <v-col cols="12">
          <v-btn
            block
            class="pa-1"
            large
            color="warning"
            dark
            @click="back_to_invoice"
            >{{ __("Back") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import format from "../../format";
export default {
  mixins: [format],
  data: () => ({
    loading: false,
    pos_profile: "",
    pos_offers: [],
    allItems: [],
    discount_percentage_offer_name: null,
    expanded: [],
    singleExpand: true,
    items_headers: [
      { title: __("Name"), key: "name", align: "start" },
      { title: __("Apply On"), key: "apply_on", align: "start" },
      { title: __("Offer"), key: "offer", align: "start" },
      { title: __("Applied"), key: "offer_applied", align: "start" },
    ],
  }),

  computed: {
    offersCount() {
      return this.pos_offers.length;
    },
    appliedOffersCount() {
      return this.pos_offers.filter((el) => !!el.offer_applied).length;
    },
  },

  methods: {
    back_to_invoice() {
      this.emitter.emit("show_offers", "false");
    },
    forceUpdateItem() {
      let list_offers = [];
      list_offers = [...this.pos_offers];
      this.pos_offers = list_offers;
    },
    makeid(length) {
      let result = "";
      const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    updatePosOffers(offers) {
      const toRemove = [];
      this.pos_offers.forEach((pos_offer) => {
        const offer = offers.find((offer) => offer.name === pos_offer.name);
        if (!offer) {
          toRemove.push(pos_offer.row_id);
        }
      });
      this.removeOffers(toRemove);
      offers.forEach((offer) => {
        const pos_offer = this.pos_offers.find(
          (pos_offer) => offer.name === pos_offer.name
        );
        if (pos_offer) {
          pos_offer.items = offer.items;
          if (
            pos_offer.offer === "Grand Total" &&
            !this.discount_percentage_offer_name
          ) {
            pos_offer.offer_applied = !!pos_offer.auto;
          }
          if (
            offer.apply_on == "Item Group" &&
            offer.apply_type == "Item Group" &&
            offer.replace_cheapest_item
          ) {
            pos_offer.give_item = offer.give_item;
            pos_offer.apply_item_code = offer.apply_item_code;
          }
        } else {
          const newOffer = { ...offer };
          if (!offer.row_id) {
            newOffer.row_id = this.makeid(20);
          }
          if (offer.apply_type == "Item Code") {
            newOffer.give_item = offer.apply_item_code || "Nothing";
          }
          if (offer.offer_applied) {
            newOffer.offer_applied == !!offer.offer_applied;
          } else {
            if (
              offer.apply_type == "Item Group" &&
              offer.offer == "Give Product" &&
              !offer.replace_cheapest_item &&
              !offer.replace_item
            ) {
              newOffer.offer_applied = false;
            } else if (
              offer.offer === "Grand Total" &&
              this.discount_percentage_offer_name
            ) {
              newOffer.offer_applied = false;
            } else {
              newOffer.offer_applied = !!offer.auto;
            }
          }
          if (newOffer.offer == "Give Product" && !newOffer.give_item) {
            newOffer.give_item = this.get_give_items(newOffer)[0].item_code;
          }
          this.pos_offers.push(newOffer);
          this.emitter.emit("show_mesage", {
            text: __("New Offer Available"),
            color: "warning",
          });
        }
      });
    },
    removeOffers(offers_id_list) {
      this.pos_offers = this.pos_offers.filter(
        (offer) => !offers_id_list.includes(offer.row_id)
      );
    },
    handelOffers() {
      const applyedOffers = this.pos_offers.filter(
        (offer) => offer.offer_applied
      );
      this.emitter.emit("update_invoice_offers", applyedOffers);
    },
    handleNewLine(str) {
      if (str) {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
    get_give_items(offer) {
      if (offer.apply_type == "Item Code") {
        return [offer.apply_item_code];
      } else if (offer.apply_type == "Item Group") {
        const items = this.allItems;
        let filterd_items = [];
        const filterd_items_1 = items.filter(
          (item) => item.item_group == offer.apply_item_group
        );
        if (offer.less_then > 0) {
          filterd_items = filterd_items_1.filter(
            (item) => item.rate < offer.less_then
          );
        } else {
          filterd_items = filterd_items_1;
        }
        const __filterd_items = [];
        // push the items to the __filterd_items array
        filterd_items.forEach((item) => {
          __filterd_items.push({
            code: item.item_code,
            name: item.item_name,
          });
        });
        return __filterd_items;
      } else {
        return [];
      }
    },
    updateCounters() {
      this.emitter.emit("update_offers_counters", {
        offersCount: this.offersCount,
        appliedOffersCount: this.appliedOffersCount,
      });
    },
    updatePosCoupuns() {
      const applyedOffers = this.pos_offers.filter(
        (offer) => offer.offer_applied && offer.coupon_based
      );
      this.emitter.emit("update_pos_coupons", applyedOffers);
    },
  },

  watch: {
    pos_offers: {
      deep: true,
      handler(pos_offers) {
        this.handelOffers();
        this.updateCounters();
        this.updatePosCoupuns();
      },
    },
  },

  created: function () {
    this.$nextTick(function () {
      this.emitter.on("register_pos_profile", (data) => {
        this.pos_profile = data.pos_profile;
      });
    });
    this.emitter.on("update_customer", (customer) => {
      if (this.customer != customer) {
        this.offers = [];
      }
    });
    this.emitter.on("update_pos_offers", (data) => {
      this.updatePosOffers(data);
    });
    this.emitter.on("update_discount_percentage_offer_name", (data) => {
      this.discount_percentage_offer_name = data.value;
    });
    this.emitter.on("set_all_items", (data) => {
      this.allItems = data;
    });
  },
};
</script>
