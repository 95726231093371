import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-h6 primary--text" }
const _hoisted_2 = {
  class: "my-0 py-0 overflow-y-auto",
  style: {"max-height":"75vh"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      class: "selection mx-auto bg-grey-lighten-5",
      style: {"max-height":"80vh","height":"80vh"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              "no-gutters": "",
              align: "center",
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "6" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Coupons")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      density: "compact",
                      variant: "outlined",
                      color: "primary",
                      label: _ctx.frappe._('Coupon'),
                      "background-color": "white",
                      "hide-details": "",
                      modelValue: _ctx.new_coupon,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.new_coupon) = $event)),
                      class: "mr-4"
                    }, null, 8 /* PROPS */, ["label", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "pa-1",
                      color: "success",
                      dark: "",
                      onClick: _cache[1] || (_cache[1] = $event => ($options.add_coupon(_ctx.new_coupon)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.__("add")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_data_table, {
            onMouseover: _cache[2] || (_cache[2] = $event => (_ctx.style = 'cursor: pointer')),
            headers: _ctx.items_headers,
            items: _ctx.posa_coupons,
            "single-expand": _ctx.singleExpand,
            expanded: _ctx.expanded,
            "item-value": "coupon",
            class: "elevation-1",
            "items-per-page": "-1",
            "hide-default-footer": ""
          }, {
            "item.applied": _withCtx(({ item }) => [
              (!!item.applied)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    icon: "mdi-check",
                    class: "ml-2",
                    color: "primary",
                    title: "Offer"
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            bottom: _withCtx(() => []),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["headers", "items", "single-expand", "expanded"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_card, {
      flat: "",
      style: {"max-height":"11vh","height":"11vh"},
      class: "cards mb-0 mt-3 py-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "start",
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  block: "",
                  class: "pa-1",
                  large: "",
                  color: "warning",
                  dark: "",
                  onClick: $options.back_to_invoice
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Back")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}